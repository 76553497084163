import MainPage from "./Pages/MainPage/MainPage";
import GkPage from "./Pages/GkPage/GkPage";
import FlatPage from "./Pages/FlatPage/FlatPage";
import Footer from "./Modules/Footer/Footer";
import Form from "./Modules/Form/Form";
import Header from "./Modules/Header/Header";
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  return (
    <>
      <Header/>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<MainPage />}/>
          <Route path="/gk/:gkId" element={<GkPage />}/>
          <Route path="/flat/:gkId/:flatId" element={<FlatPage />}/>
        </Routes>
      </BrowserRouter>
      <Form />
      <Footer />
    </>
  );
}

export default App;
