import React from 'react';

const Review = ({text,image, name, proffesion, id, className}) => {
    return (
            <div className={`p-3 ${className}`}>
                <p className={'testimonials-text'}>{text}</p>
                
                <div className={'testimonials-info'}>
                    <div className={'testimonials-img'}>
                        <img className={'testimonials-img__icon'} src={image} alt=""/>
                    </div>
                    <div>
                        <h5 className="mb-1 testimonials-name">{name}</h5>
                        <span className="testimonials-proffesion">{proffesion}</span>
                    </div>
                </div>
            </div>
    );
};
export default Review;
