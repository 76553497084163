import React from 'react';
import Hat from "./Hat/Hat";
import Flats from "./Flats/Flats";
import Rules from "./Rules/Rules";
import Abouts from "./Abouts/Abouts";
import Reviews from "./Reviews/Reviews";

import {useParams} from 'react-router';

import data from "../../Constants/data.js";

const GkPage = () => {

  let {gkId} = useParams();
  const currentGk = data[gkId-1];

    return (
      <div>
        <Hat info={currentGk}/>
        <Flats info={currentGk}/>
        <Rules info={currentGk}/>
        <Abouts info={currentGk}/>
        <Reviews info={currentGk.reviews}/>
      </div>
    );
};

export default GkPage;
