const data = [
  {
    "id": 1,
    "name": "ЖК Комфорт",
    "photo": "https://img.lunstatic.net/building-440x330/31001.jpg",
    "location": "Центр Трускавця", 
    "description": "Вже давно відомо, що читабельний зміст буде заважати зосередитись людині, яка оцінює композицію сторінки. Сенс використання Lorem Ipsum полягає в тому, що цей текст має більш-менш нормальне розподілення літер на відміну від, наприклад, Тут іде текст. Тут іде текст. Це робить текст схожим на оповідний. Багато програм верстування та веб-дизайну використовують Lorem Ipsum як зразок і пошук за терміном lorem ipsum відкриє багато веб-сайтів, які знаходяться ще в зародковому стані. Різні версії Lorem Ipsum з'явились за минулі роки, деякі випадково, деякі було створено зумисно (зокрема, жартівливі).",
    "flats": [
      {
        "id": 1,
        "name": "Квартира студія",
        "rooms": 2,
        "photos": ["https://img.lunstatic.net/building-440x330/31001.jpg", "https://img.lunstatic.net/building-440x330/31001.jpg", "https://img.lunstatic.net/building-440x330/31001.jpg", "https://img.lunstatic.net/building-440x330/31001.jpg", "https://img.lunstatic.net/building-440x330/31001.jpg", "https://img.lunstatic.net/building-440x330/31001.jpg", "https://img.lunstatic.net/building-440x330/31001.jpg", "https://img.lunstatic.net/building-440x330/31001.jpg", "https://img.lunstatic.net/building-440x330/31001.jpg", "https://img.lunstatic.net/building-440x330/31001.jpg"],
        "description": "це текст- риба, що використовується в друкарстві та дизайні. Lorem Ipsum є, фактично, стандартною рибо аж з XVI сторіччя, коли невідомий друкар взяв шрифтову гранку та склав на ній підбірку зразків шрифтів. Риба не тільки успішно пережила п'ять століть, але й прижилася в електронному верстуванні, залишаючись по суті незмінною. Вона популяризувалась в 60-их роках минулого сторіччя завдяки виданню зразків шрифтів Letraset, які містили уривки з Lorem Ipsum, і вдруге - нещодавно завдяки програмам комп'ютерного верстування на кшталт Aldus Pagemaker, які використовували різні версії Lorem Ipsum.",
        "area": "32",
        "beds": "2",
        "price": "1200",
        "details": {
          "gasStove": true,
          "dishes": false,
          "refrigerator": false,
          "microwave": false,
          "shower": true,
          "washingMachine": true,
          "washbasin": false,
          "smartTv": true,
          "internet": true,
          "megogo": false
        }
      },
      {
        "id": 2,
        "name": "Квартира студія",
        "rooms": 2,
        "photos": ["https://img.lunstatic.net/building-440x330/31001.jpg", "https://img.lunstatic.net/building-440x330/31001.jpg", "https://img.lunstatic.net/building-440x330/31001.jpg"],
        "description": "це текст- риба, що використовується в друкарстві та дизайні. Lorem Ipsum є, фактично, стандартною рибо аж з XVI сторіччя, коли невідомий друкар взяв шрифтову гранку та склав на ній підбірку зразків шрифтів. Риба не тільки успішно пережила п'ять століть, але й прижилася в електронному верстуванні, залишаючись по суті незмінною. Вона популяризувалась в 60-их роках минулого сторіччя завдяки виданню зразків шрифтів Letraset, які містили уривки з Lorem Ipsum, і вдруге - нещодавно завдяки програмам комп'ютерного верстування на кшталт Aldus Pagemaker, які використовували різні версії Lorem Ipsum.",
        "area": "42",
        "beds": "4",
        "price": "2000",
        "details": {
          "gasStove": true,
          "dishes": false,
          "refrigerator": false,
          "microwave": false,
          "shower": true,
          "washingMachine": true,
          "washbasin": false,
          "smartTv": true,
          "internet": true,
          "megogo": false
        }
      }
    ],
    "rules": {
      "pets": true,
      "checkIn": "day and night",
      "checkOut": "12:00"
    },
    "reviews": [
      {
        name: "Прізвище та ім'я",
        photo: "https://shorturl.at/izIXZ",
        occupation: "Менеджер",
        review: "everything is really good!!"
      },
      {
        name: "Прізвище та ім'я",
        photo: "https://shorturl.at/izIXZ",
        occupation: "Менеджер",
        review: "uncomfortable beds"
      },
      {
        name: "Прізвище та ім'я",
        photo: "https://shorturl.at/izIXZ",
        occupation: "Менеджер",
        review: "really cheap! Love it!"
      },
      {
        name: "Прізвище та ім'я",
        photo: "https://shorturl.at/izIXZ",
        occupation: "Менеджер",
        review: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into",
      },
      {
        name: "Прізвище та ім'я",
        photo: "https://shorturl.at/izIXZ",
        occupation: "Менеджер",
        review: "uncomfortable beds"
      },
      {
        name: "Прізвище та ім'я",
        photo: "https://shorturl.at/izIXZ",
        occupation: "Менеджер",
        review: "really cheap! Love it!"
      },
    ]
  },
  {
    "id": 2,
    "name": "ЖК Комфорт",
    "photo": "https://img.lunstatic.net/building-440x330/31001.jpg",
    "location": "Центр Трускавця", 
    "description": "Вже давно відомо, що читабельний зміст буде заважати зосередитись людині, яка оцінює композицію сторінки. Сенс використання Lorem Ipsum полягає в тому, що цей текст має більш-менш нормальне розподілення літер на відміну від, наприклад, Тут іде текст. Тут іде текст. Це робить текст схожим на оповідний. Багато програм верстування та веб-дизайну використовують Lorem Ipsum як зразок і пошук за терміном lorem ipsum відкриє багато веб-сайтів, які знаходяться ще в зародковому стані. Різні версії Lorem Ipsum з'явились за минулі роки, деякі випадково, деякі було створено зумисно (зокрема, жартівливі).",
    "flats": [
      {
        "id": 1,
        "name": "Квартира студія",
        "rooms": 2,
        "photos": ["https://img.lunstatic.net/building-440x330/31001.jpg", "https://img.lunstatic.net/building-440x330/31001.jpg", "https://img.lunstatic.net/building-440x330/31001.jpg"],
        "description": "це текст- риба, що використовується в друкарстві та дизайні. Lorem Ipsum є, фактично, стандартною рибо аж з XVI сторіччя, коли невідомий друкар взяв шрифтову гранку та склав на ній підбірку зразків шрифтів. Риба не тільки успішно пережила п'ять століть, але й прижилася в електронному верстуванні, залишаючись по суті незмінною. Вона популяризувалась в 60-их роках минулого сторіччя завдяки виданню зразків шрифтів Letraset, які містили уривки з Lorem Ipsum, і вдруге - нещодавно завдяки програмам комп'ютерного верстування на кшталт Aldus Pagemaker, які використовували різні версії Lorem Ipsum.",
        "area": "32",
        "beds": "2",
        "price": "1200",
        "details": {
          "gasStove": true,
          "dishes": false,
          "refrigerator": false,
          "microwave": false,
          "shower": true,
          "washingMachine": true,
          "washbasin": false,
          "smartTv": true,
          "internet": true,
          "megogo": false
        }
      },
      {
        "id": 2,
        "name": "Квартира студія",
        "rooms": 2,
        "photos": ["https://img.lunstatic.net/building-440x330/31001.jpg", "https://img.lunstatic.net/building-440x330/31001.jpg", "https://img.lunstatic.net/building-440x330/31001.jpg"],
        "description": "це текст- риба, що використовується в друкарстві та дизайні. Lorem Ipsum є, фактично, стандартною рибо аж з XVI сторіччя, коли невідомий друкар взяв шрифтову гранку та склав на ній підбірку зразків шрифтів. Риба не тільки успішно пережила п'ять століть, але й прижилася в електронному верстуванні, залишаючись по суті незмінною. Вона популяризувалась в 60-их роках минулого сторіччя завдяки виданню зразків шрифтів Letraset, які містили уривки з Lorem Ipsum, і вдруге - нещодавно завдяки програмам комп'ютерного верстування на кшталт Aldus Pagemaker, які використовували різні версії Lorem Ipsum.",
        "area": "42",
        "beds": "4",
        "price": "2000",
        "details": {
          "gasStove": true,
          "dishes": false,
          "refrigerator": false,
          "microwave": false,
          "shower": true,
          "washingMachine": true,
          "washbasin": false,
          "smartTv": true,
          "internet": true,
          "megogo": false
        }
      }
    ],
    "rules": {
      "pets": true,
      "checkIn": "day and night",
      "checkOut": "12:00"
    },
    "reviews": [
      {
        name: "Прізвище та ім'я",
        photo: "https://shorturl.at/izIXZ",
        occupation: "Менеджер",
        review: "everything is really good!!"
      },
      {
        name: "Прізвище та ім'я",
        photo: "https://shorturl.at/izIXZ",
        occupation: "Менеджер",
        review: "uncomfortable beds"
      },
      {
        name: "Прізвище та ім'я",
        photo: "https://shorturl.at/izIXZ",
        occupation: "Менеджер",
        review: "really cheap! Love it!"
      },
    ]
  },
  {
    "id": 3,
    "name": "ЖК Комфорт",
    "photo": "https://img.lunstatic.net/building-440x330/31001.jpg",
    "location": "Центр Трускавця", 
    "description": "Вже давно відомо, що читабельний зміст буде заважати зосередитись людині, яка оцінює композицію сторінки. Сенс використання Lorem Ipsum полягає в тому, що цей текст має більш-менш нормальне розподілення літер на відміну від, наприклад, Тут іде текст. Тут іде текст. Це робить текст схожим на оповідний. Багато програм верстування та веб-дизайну використовують Lorem Ipsum як зразок і пошук за терміном lorem ipsum відкриє багато веб-сайтів, які знаходяться ще в зародковому стані. Різні версії Lorem Ipsum з'явились за минулі роки, деякі випадково, деякі було створено зумисно (зокрема, жартівливі).",
    "flats": [
      {
        "id": 1,
        "name": "Квартира студія",
        "rooms": 2,
        "photos": ["https://img.lunstatic.net/building-440x330/31001.jpg", "https://img.lunstatic.net/building-440x330/31001.jpg", "https://img.lunstatic.net/building-440x330/31001.jpg"],
        "description": "це текст- риба, що використовується в друкарстві та дизайні. Lorem Ipsum є, фактично, стандартною рибо аж з XVI сторіччя, коли невідомий друкар взяв шрифтову гранку та склав на ній підбірку зразків шрифтів. Риба не тільки успішно пережила п'ять століть, але й прижилася в електронному верстуванні, залишаючись по суті незмінною. Вона популяризувалась в 60-их роках минулого сторіччя завдяки виданню зразків шрифтів Letraset, які містили уривки з Lorem Ipsum, і вдруге - нещодавно завдяки програмам комп'ютерного верстування на кшталт Aldus Pagemaker, які використовували різні версії Lorem Ipsum.",
        "area": "32",
        "beds": "2",
        "price": "1200",
        "details": {
          "gasStove": true,
          "dishes": false,
          "refrigerator": false,
          "microwave": false,
          "shower": true,
          "washingMachine": true,
          "washbasin": false,
          "smartTv": true,
          "internet": true,
          "megogo": false
        }
      },
      {
        "id": 2,
        "name": "Квартира студія",
        "rooms": 2,
        "photos": ["https://img.lunstatic.net/building-440x330/31001.jpg", "https://img.lunstatic.net/building-440x330/31001.jpg", "https://img.lunstatic.net/building-440x330/31001.jpg"],
        "description": "це текст- риба, що використовується в друкарстві та дизайні. Lorem Ipsum є, фактично, стандартною рибо аж з XVI сторіччя, коли невідомий друкар взяв шрифтову гранку та склав на ній підбірку зразків шрифтів. Риба не тільки успішно пережила п'ять століть, але й прижилася в електронному верстуванні, залишаючись по суті незмінною. Вона популяризувалась в 60-их роках минулого сторіччя завдяки виданню зразків шрифтів Letraset, які містили уривки з Lorem Ipsum, і вдруге - нещодавно завдяки програмам комп'ютерного верстування на кшталт Aldus Pagemaker, які використовували різні версії Lorem Ipsum.",
        "area": "42",
        "beds": "4",
        "price": "2000",
        "details": {
          "gasStove": true,
          "dishes": false,
          "refrigerator": false,
          "microwave": false,
          "shower": true,
          "washingMachine": true,
          "washbasin": false,
          "smartTv": true,
          "internet": true,
          "megogo": false
        }
      }
    ],
    "rules": {
      "pets": true,
      "checkIn": "day and night",
      "checkOut": "12:00"
    },
    "reviews": [
      {
        name: "Прізвище та ім'я",
        photo: "https://shorturl.at/izIXZ",
        occupation: "Менеджер",
        review: "everything is really good!!"
      },
      {
        name: "Прізвище та ім'я",
        photo: "https://shorturl.at/izIXZ",
        occupation: "Менеджер",
        review: "uncomfortable beds"
      },
      {
        name: "Прізвище та ім'я",
        photo: "https://shorturl.at/izIXZ",
        occupation: "Менеджер",
        review: "really cheap! Love it!"
      },
    ]
  },
  {
    "id": 4,
    "name": "ЖК Комфорт",
    "photo": "https://img.lunstatic.net/building-440x330/31001.jpg",
    "location": "Центр Трускавця", 
    "description": "Вже давно відомо, що читабельний зміст буде заважати зосередитись людині, яка оцінює композицію сторінки. Сенс використання Lorem Ipsum полягає в тому, що цей текст має більш-менш нормальне розподілення літер на відміну від, наприклад, Тут іде текст. Тут іде текст. Це робить текст схожим на оповідний. Багато програм верстування та веб-дизайну використовують Lorem Ipsum як зразок і пошук за терміном lorem ipsum відкриє багато веб-сайтів, які знаходяться ще в зародковому стані. Різні версії Lorem Ipsum з'явились за минулі роки, деякі випадково, деякі було створено зумисно (зокрема, жартівливі).",
    "flats": [
      {
        "id": 1,
        "name": "Квартира студія",
        "rooms": 2,
        "photos": ["https://img.lunstatic.net/building-440x330/31001.jpg", "https://img.lunstatic.net/building-440x330/31001.jpg", "https://img.lunstatic.net/building-440x330/31001.jpg"],
        "description": "це текст- риба, що використовується в друкарстві та дизайні. Lorem Ipsum є, фактично, стандартною рибо аж з XVI сторіччя, коли невідомий друкар взяв шрифтову гранку та склав на ній підбірку зразків шрифтів. Риба не тільки успішно пережила п'ять століть, але й прижилася в електронному верстуванні, залишаючись по суті незмінною. Вона популяризувалась в 60-их роках минулого сторіччя завдяки виданню зразків шрифтів Letraset, які містили уривки з Lorem Ipsum, і вдруге - нещодавно завдяки програмам комп'ютерного верстування на кшталт Aldus Pagemaker, які використовували різні версії Lorem Ipsum.",
        "area": "32",
        "beds": "2",
        "price": "1200",
        "details": {
          "gasStove": true,
          "dishes": false,
          "refrigerator": false,
          "microwave": false,
          "shower": true,
          "washingMachine": true,
          "washbasin": false,
          "smartTv": true,
          "internet": true,
          "megogo": false
        }
      },
      {
        "id": 2,
        "name": "Квартира студія",
        "rooms": 2,
        "photos": ["https://img.lunstatic.net/building-440x330/31001.jpg", "https://img.lunstatic.net/building-440x330/31001.jpg", "https://img.lunstatic.net/building-440x330/31001.jpg"],
        "description": "це текст- риба, що використовується в друкарстві та дизайні. Lorem Ipsum є, фактично, стандартною рибо аж з XVI сторіччя, коли невідомий друкар взяв шрифтову гранку та склав на ній підбірку зразків шрифтів. Риба не тільки успішно пережила п'ять століть, але й прижилася в електронному верстуванні, залишаючись по суті незмінною. Вона популяризувалась в 60-их роках минулого сторіччя завдяки виданню зразків шрифтів Letraset, які містили уривки з Lorem Ipsum, і вдруге - нещодавно завдяки програмам комп'ютерного верстування на кшталт Aldus Pagemaker, які використовували різні версії Lorem Ipsum.",
        "area": "42",
        "beds": "4",
        "price": "2000",
        "details": {
          "gasStove": true,
          "dishes": false,
          "refrigerator": false,
          "microwave": false,
          "shower": true,
          "washingMachine": true,
          "washbasin": false,
          "smartTv": true,
          "internet": true,
          "megogo": false
        }
      }
    ],
    "rules": {
      "pets": true,
      "checkIn": "day and night",
      "checkOut": "12:00"
    },
    "reviews": [
      {
        name: "Прізвище та ім'я",
        photo: "https://shorturl.at/izIXZ",
        occupation: "Менеджер",
        review: "everything is really good!!"
      },
      {
        name: "Прізвище та ім'я",
        photo: "https://shorturl.at/izIXZ",
        occupation: "Менеджер",
        review: "uncomfortable beds"
      },
      {
        name: "Прізвище та ім'я",
        photo: "https://shorturl.at/izIXZ",
        occupation: "Менеджер",
        review: "really cheap! Love it!"
      },
    ]
  },
  {
    "id": 5,
    "name": "ЖК Комфорт",
    "photo": "https://img.lunstatic.net/building-440x330/31001.jpg",
    "location": "Центр Трускавця", 
    "description": "Вже давно відомо, що читабельний зміст буде заважати зосередитись людині, яка оцінює композицію сторінки. Сенс використання Lorem Ipsum полягає в тому, що цей текст має більш-менш нормальне розподілення літер на відміну від, наприклад, Тут іде текст. Тут іде текст. Це робить текст схожим на оповідний. Багато програм верстування та веб-дизайну використовують Lorem Ipsum як зразок і пошук за терміном lorem ipsum відкриє багато веб-сайтів, які знаходяться ще в зародковому стані. Різні версії Lorem Ipsum з'явились за минулі роки, деякі випадково, деякі було створено зумисно (зокрема, жартівливі).",
    "flats": [
      {
        "id": 1,
        "name": "Квартира студія",
        "rooms": 2,
        "photos": ["https://img.lunstatic.net/building-440x330/31001.jpg", "https://img.lunstatic.net/building-440x330/31001.jpg", "https://img.lunstatic.net/building-440x330/31001.jpg"],
        "description": "це текст- риба, що використовується в друкарстві та дизайні. Lorem Ipsum є, фактично, стандартною рибо аж з XVI сторіччя, коли невідомий друкар взяв шрифтову гранку та склав на ній підбірку зразків шрифтів. Риба не тільки успішно пережила п'ять століть, але й прижилася в електронному верстуванні, залишаючись по суті незмінною. Вона популяризувалась в 60-их роках минулого сторіччя завдяки виданню зразків шрифтів Letraset, які містили уривки з Lorem Ipsum, і вдруге - нещодавно завдяки програмам комп'ютерного верстування на кшталт Aldus Pagemaker, які використовували різні версії Lorem Ipsum.",
        "area": "32",
        "beds": "2",
        "price": "1200",
        "details": {
          "gasStove": true,
          "dishes": false,
          "refrigerator": false,
          "microwave": false,
          "shower": true,
          "washingMachine": true,
          "washbasin": false,
          "smartTv": true,
          "internet": true,
          "megogo": false
        }
      },
      {
        "id": 2,
        "name": "Квартира студія",
        "rooms": 2,
        "photos": ["https://img.lunstatic.net/building-440x330/31001.jpg", "https://img.lunstatic.net/building-440x330/31001.jpg", "https://img.lunstatic.net/building-440x330/31001.jpg"],
        "description": "це текст- риба, що використовується в друкарстві та дизайні. Lorem Ipsum є, фактично, стандартною рибо аж з XVI сторіччя, коли невідомий друкар взяв шрифтову гранку та склав на ній підбірку зразків шрифтів. Риба не тільки успішно пережила п'ять століть, але й прижилася в електронному верстуванні, залишаючись по суті незмінною. Вона популяризувалась в 60-их роках минулого сторіччя завдяки виданню зразків шрифтів Letraset, які містили уривки з Lorem Ipsum, і вдруге - нещодавно завдяки програмам комп'ютерного верстування на кшталт Aldus Pagemaker, які використовували різні версії Lorem Ipsum.",
        "area": "42",
        "beds": "4",
        "price": "2000",
        "details": {
          "gasStove": true,
          "dishes": false,
          "refrigerator": false,
          "microwave": false,
          "shower": true,
          "washingMachine": true,
          "washbasin": false,
          "smartTv": true,
          "internet": true,
          "megogo": false
        }
      }
    ],
    "rules": {
      "pets": true,
      "checkIn": "day and night",
      "checkOut": "12:00"
    },
    "reviews": [
      {
        name: "Прізвище та ім'я",
        photo: "https://shorturl.at/izIXZ",
        occupation: "Менеджер",
        review: "everything is really good!!"
      },
      {
        name: "Прізвище та ім'я",
        photo: "https://shorturl.at/izIXZ",
        occupation: "Менеджер",
        review: "uncomfortable beds"
      },
      {
        name: "Прізвище та ім'я",
        photo: "https://shorturl.at/izIXZ",
        occupation: "Менеджер",
        review: "really cheap! Love it!"
      },
    ]
  }
]

export default data;