import React from 'react';
import styles from './style.module.css'


const NearFlat = () => {


    const doSmth = () => {
        console.log('перехід на іншу сторінку')
    }


    return (

            <div className={'container'}>
                <div className={styles.wrap}>
                <h3 className={styles.title}>Об’єкти поруч:</h3>

                <button className={styles.btn} onClick={()=> doSmth()}>Переглянути більше</button>
            </div>
        </div>
    );
};

export default NearFlat;

