import React from 'react'
import styles from './styles.module.css'
import {
  cancelIcon,
  checkIcon,
  countBadRoomIcon,
  countRoomIcon,
  ploshchaIcon,
  PriceIcon,
  timingIcon,
} from '../../../../Assets/Icons/iconsPage3'

import { Link } from 'react-scroll'

const AboutFlat = ({ flat }) => {
  return (
    <div className={'container'}>
      <div className={styles.container}>
        <div className={styles.container_metrics}>
          <div className={styles.container_metrics__metric}>
            <img src={PriceIcon} alt={PriceIcon} />
            Ціна: <span>{flat.price} грн</span>
          </div>
          <div className={styles.container_metrics__metric}>
            <img src={countBadRoomIcon} alt={countBadRoomIcon} />
            Спальні місць: <span>{flat.beds}</span>
          </div>
          <div className={styles.container_metrics__metric}>
            <img src={countRoomIcon} alt={countRoomIcon} />
            Кількість кімнат: <span>{flat.rooms}</span>
          </div>
          <div className={styles.container_metrics__metric}>
            <img src={ploshchaIcon} alt={ploshchaIcon} />
            Площа: <span>{flat.area} кв м²</span>
          </div>
        </div>

        <div className={styles.container_timing}>
          <img src={timingIcon} alt={timingIcon} />
          <p>
            Мінімальний час перебування: <span>3 доби</span>
          </p>
        </div>

        <Link className={styles.container_btn}>
          <button className={styles.container_btn__btn}>Забронювати</button>
        </Link>

        <div className={styles.container_whatWeHave}>
          <p className={styles.container_whatWeHave__description}>
            {flat.description}
          </p>

          <div className={styles.whatWeHave_insideBoxes}>
            <div className={styles.box}>
              <h3 className={styles.box__title}>Кухня:</h3>
              <div className={styles.box_contaIner}>
                <div className={styles.box_contaIner__target}>
                  {flat.details.gasStove ? (
                    <img src={checkIcon} alt="yes" />
                  ) : (
                    <img src={cancelIcon} alt="no" />
                  )}
                  <p>Газова плита</p>
                </div>
                <div className={styles.box_contaIner__target}>
                  {flat.details.refrigerator ? (
                    <img src={checkIcon} alt="yes" />
                  ) : (
                    <img src={cancelIcon} alt="no" />
                  )}
                  <p>Холодильник</p>
                </div>
                <div className={styles.box_contaIner__target}>
                  {flat.details.microwave ? (
                    <img src={checkIcon} alt="yes" />
                  ) : (
                    <img src={cancelIcon} alt="no" />
                  )}
                  <p>Мікрохвильова піч</p>
                </div>
                <div className={styles.box_contaIner__target}>
                  {flat.details.dishes ? (
                    <img src={checkIcon} alt="yes" />
                  ) : (
                    <img src={cancelIcon} alt="no" />
                  )}
                  <p>Необхідний посуд</p>
                </div>
              </div>
            </div>

            <div className={styles.box}>
              <h3 className={styles.box__title}>Ванна:</h3>
              <div className={styles.box_contaIner}>
                <div className={styles.box_contaIner__target}>
                  {flat.details.shower ? (
                    <img src={checkIcon} alt="yes" />
                  ) : (
                    <img src={cancelIcon} alt="no" />
                  )}
                  <p>Душова кабіна</p>
                </div>
                <div className={styles.box_contaIner__target}>
                  {flat.details.washingMachine ? (
                    <img src={checkIcon} alt="yes" />
                  ) : (
                    <img src={cancelIcon} alt="no" />
                  )}
                  <p>Пральна машина</p>
                </div>
                <div className={styles.box_contaIner__target}>
                  {flat.details.washbasin ? (
                    <img src={checkIcon} alt="yes" />
                  ) : (
                    <img src={cancelIcon} alt="no" />
                  )}
                  <p>Умивальник</p>
                </div>
              </div>
            </div>
            <div className={styles.box}>
              <h3 className={styles.box__title}>Мультимедія:</h3>
              <div className={styles.box_contaIner}>
                <div className={styles.box_contaIner__target}>
                  {flat.details.smartTv ? (
                    <img src={checkIcon} alt="yes" />
                  ) : (
                    <img src={cancelIcon} alt="no" />
                  )}
                  <p>Смарт TV</p>
                </div>
                <div className={styles.box_contaIner__target}>
                  {flat.details.internet ? (
                    <img src={checkIcon} alt="yes" />
                  ) : (
                    <img src={cancelIcon} alt="no" />
                  )}
                  <p>Інтернет</p>
                </div>
                <div className={styles.box_contaIner__target}>
                  {flat.details.megogo ? (
                    <img src={checkIcon} alt="yes" />
                  ) : (
                    <img src={cancelIcon} alt="no" />
                  )}
                  <p>MEGOGO</p>
                </div>
              </div>
            </div>
            <div className={styles.box}>
              <h3 className={styles.box__title}>Правила проживання:</h3>
              <div className={styles.box_contaIner}>
                <div className={styles.box_contaIner__target}>
                  <p>
                    <span>Заїзд:</span> Цілодобовий <br /> <span>Виїзд:</span>{' '}
                    До 12:00
                  </p>
                </div>
                <div className={styles.box_contaIner__target}>
                  <p>
                    <span>2 гостя.</span> <br /> З тваринами до 5 кг
                  </p>
                </div>
                <div className={styles.box_contaIner__target}>
                  <p>
                    <span>18+</span> <br /> Вікове обмежження
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutFlat
