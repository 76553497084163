import React from 'react'

import Hat from "./Hat/Hat.js";
import Map from "./Map/Map.js";
import Houses from "./Houses/Houses.js";


export default function MainPage() {

  return(
    <div className="mainPage">
      <Hat/>
      <Map/>
      <Houses/>
    </div>
  )
}
