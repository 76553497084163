import React from "react";
import styles from "./style.module.css";
import {
  cancelIcon,
  checkIcon,
  countBadRoomIcon,
  countRoomIcon,
  emailIcon,
  fbIcon,
  instIcon,
  managerIcon,
  ploshchaIcon,
  PriceIcon,
  telIcon,
  tgIcon,
  timingIcon,
} from "../../../../Assets/Icons/iconsPage3";

import { Link } from "react-scroll";

const AboutFlat = ({ flat }) => {
  return (
    <div className={"container"}>
      <div className={styles.container}>
        <h2 className={styles.container__title}>{flat.name}</h2>

        <div className={styles.container_boxes}>
          <div className={styles.leftBox}>
            <div className={styles.leftBox_metrics}>
              <div className={styles.leftBox_metrics__metric}>
                <img src={PriceIcon} alt={PriceIcon} />
                Ціна: <span>{flat.price} грн</span>
              </div>
              <div className={styles.leftBox_metrics__metric}>
                <img src={countBadRoomIcon} alt={countBadRoomIcon} />
                Спальні місць: <span>{flat.beds}</span>
              </div>
              <div className={styles.leftBox_metrics__metric}>
                <img src={countRoomIcon} alt={countRoomIcon} />
                Кількість кімнат: <span>{flat.rooms}</span>
              </div>
              <div className={styles.leftBox_metrics__metric}>
                <img src={ploshchaIcon} alt={ploshchaIcon} />
                Площа: <span>{flat.area} кв м²</span>
              </div>
            </div>

            <div className={styles.leftBox_timing}>
              <img src={timingIcon} alt={timingIcon} />
              <p>
                Мінімальний час перебування: <span>3 доби</span>
              </p>
            </div>

            <div className={styles.leftBox_whatWeHave}>
              <p className={styles.leftBox_whatWeHave__description}>
                {flat.description}
              </p>

              <div className={styles.whatWeHave_insideBoxes}>
                <div className={styles.box}>
                  <h3 className={styles.box__title}>Кухня:</h3>
                  <div className={styles.box_contaIner}>
                    <div className={styles.box_contaIner__target}>
                      {flat.details.gasStove ? (
                        <img src={checkIcon} alt="yes" />
                      ) : (
                        <img src={cancelIcon} alt="no" />
                      )}
                      <p>Газова плита</p>
                    </div>
                    <div className={styles.box_contaIner__target}>
                      {flat.details.refrigerator ? (
                        <img src={checkIcon} alt="yes" />
                      ) : (
                        <img src={cancelIcon} alt="no" />
                      )}
                      <p>Холодильник</p>
                    </div>
                    <div className={styles.box_contaIner__target}>
                      {flat.details.microwave ? (
                        <img src={checkIcon} alt="yes" />
                      ) : (
                        <img src={cancelIcon} alt="no" />
                      )}
                      <p>Мікрохвильова піч</p>
                    </div>
                    <div className={styles.box_contaIner__target}>
                      {flat.details.dishes ? (
                        <img src={checkIcon} alt="yes" />
                      ) : (
                        <img src={cancelIcon} alt="no" />
                      )}
                      <p>Необхідний посуд</p>
                    </div>
                  </div>
                </div>

                <div className={styles.box}>
                  <h3 className={styles.box__title}>Ванна:</h3>
                  <div className={styles.box_contaIner}>
                    <div className={styles.box_contaIner__target}>
                      {flat.details.shower ? (
                        <img src={checkIcon} alt="yes" />
                      ) : (
                        <img src={cancelIcon} alt="no" />
                      )}
                      <p>Душова кабіна</p>
                    </div>
                    <div className={styles.box_contaIner__target}>
                      {flat.details.washingMachine ? (
                        <img src={checkIcon} alt="yes" />
                      ) : (
                        <img src={cancelIcon} alt="no" />
                      )}
                      <p>Пральна машина</p>
                    </div>
                    <div className={styles.box_contaIner__target}>
                      {flat.details.washbasin ? (
                        <img src={checkIcon} alt="yes" />
                      ) : (
                        <img src={cancelIcon} alt="no" />
                      )}
                      <p>Умивальник</p>
                    </div>
                  </div>
                </div>

                <div className={styles.box}>
                  <h3 className={styles.box__title}>Мультимедія:</h3>
                  <div className={styles.box_contaIner}>
                    <div className={styles.box_contaIner__target}>
                      {flat.details.smartTv ? (
                        <img src={checkIcon} alt="yes" />
                      ) : (
                        <img src={cancelIcon} alt="no" />
                      )}
                      <p>Смарт TV</p>
                    </div>
                    <div className={styles.box_contaIner__target}>
                      {flat.details.internet ? (
                        <img src={checkIcon} alt="yes" />
                      ) : (
                        <img src={cancelIcon} alt="no" />
                      )}
                      <p>Інтернет</p>
                    </div>
                    <div className={styles.box_contaIner__target}>
                      {flat.details.megogo ? (
                        <img src={checkIcon} alt="yes" />
                      ) : (
                        <img src={cancelIcon} alt="no" />
                      )}
                      <p>MEGOGO</p>
                    </div>
                  </div>
                </div>

                <div className={styles.box}>
                  <h3 className={styles.box__title}>Правила проживання:</h3>
                  <div className={styles.box_contaIner}>
                    <div className={styles.box_contaIner__target}>
                      <p>
                        <span>Заїзд:</span> Цілодобовий <br />{" "}
                        <span>Виїзд:</span> До 12:00
                      </p>
                    </div>
                    <div className={styles.box_contaIner__target}>
                      <p>
                        <span>2 гостя.</span> <br /> З тваринами до 5 кг
                      </p>
                    </div>
                    <div className={styles.box_contaIner__target}>
                      <p>
                        <span>18+</span> <br /> Вікове обмежження
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.rightBox}>
            <Link>
              <button className={styles.rightBox__btn}>Забронювати</button>
            </Link>
            <div className={styles.rightBox__map}>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2603.028790785422!2d23.508904912993508!3d49.27585167127254!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x473a484db2ab1bbd%3A0x17c3ab9af31cdd99!2z0LLRg9C70LjRhtGPINCc0LDRgNC60ZbRj9C90LAg0KjQsNGI0LrQtdCy0LjRh9CwLCAzLCDQotGA0YPRgdC60LDQstC10YbRjCwg0JvRjNCy0ZbQstGB0YzQutCwINC-0LHQu9Cw0YHRgtGMLCA4MjIwMA!5e0!3m2!1suk!2sua!4v1692196943168!5m2!1suk!2sua"
                width="100%"
                height="100%"
                style={{ border: "0" }}
                allowFullScreen=""
                loading="lazy"
                title={"myMap"}
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>

            <div className={styles.rightBox_managerBox}>
              <div className={styles.rightBox_managerBox__box}>
                <div>
                  <img src={managerIcon} alt={managerIcon} />
                </div>
                <p>Зв’язатись з менеджером</p>
              </div>

              <div className={styles.rightBox_managerBox__contactBoxes}>
                <div className={styles.rightBox_managerBox__contactBoxes__box}>
                  <img src={telIcon} alt={telIcon} />
                  <a href="tel:+380686868686">+380 68 68 68 686</a>
                </div>
                <div className={styles.rightBox_managerBox__contactBoxes__box}>
                  <img src={emailIcon} alt={emailIcon} />
                  <a href="mailto:tempamail@gmail.com">tempamail@gmail.com</a>
                </div>
              </div>

              <div className={styles.rightBox_managerBox_socialMediaBox}>
                <h5
                  className={styles.rightBox_managerBox_socialMediaBox__title}
                >
                  Ми у соц. мережах
                </h5>
                <div
                  className={styles.rightBox_managerBox_socialMediaBox__links}
                >
                  <a href="https://www.facebook.com/sribnyydim/">
                    <div>
                      <img src={tgIcon} alt={tgIcon} />
                    </div>
                  </a>
                  <a href="https://www.facebook.com/sribnyydim/">
                    <div>
                      <img src={fbIcon} alt={fbIcon} />
                    </div>
                  </a>
                  <a href="https://www.instagram.com/sribnyydim/?hl=uk">
                    <div>
                      <img src={instIcon} alt={instIcon} />
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutFlat;
