import React from 'react';
import "./Abouts.scss"
import {AboutGk} from "../../../Assets/Images/images";


const Abouts = ({info}) => {

    return (
        <div className={'container houses'}>
            <div className={'houseCard'}>
                <img src={AboutGk} alt="gk_about" className={'houseCard__photo'}/>
                <div className={'houseCard__info'}>
                    <div className={"houseCard__title"}>
                        <h2>
                            Про нас:
                        </h2>
                    </div>
                    <div className={"houseCard__description"}>
                      {info.description}
                    </div>
                </div>
            </div>
        </div>
    );
};


export default Abouts;
