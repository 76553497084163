import {React, useState} from "react";
import "./Map.scss";
import MapSvg from "./MapSvg/MapSvg.js";
import HoverPopup from "../../../Modules/HoverPopup/HoverPopup.js";

const tempData = {
  house1: {
    title: "ЖК Комфорт",
    photo: "https://novobudovy.com/images/wm/14dc07850086b90e403af6a018520b70.jpg"
  },
  house2: {
    title: "ЖК Не комфорт >:(",
    photo: "https://static3.depositphotos.com/1004057/193/i/950/depositphotos_1934247-stock-photo-old-barn.jpg"
  },
  house3: {
    title: "ЖК Дуже комфорт!!",
    photo: "https://img.lunstatic.net/building-1600x1200/65298.jpg"
  },
  house4: {
    title: "ЖК Веселка",
    photo: "https://assets.puzzlefactory.com/puzzle/269/306/original.jpg"
  },
  sanatory: {
    title: "Санаторій",
    photo: "https://www.truskavich.com/wp-content/uploads/2013/12/6R4V8657.jpg"
  },
  kids: {
    title: "ЖК Комфорт",
    photo: "https://elitepark.net/data_resized/c/9/1/2/6/c9126ad05126fc8d3774fa6ea80db4bfaf49a679.jpeg"
  },
  park: {
    title: "Парк",
    photo: "https://s.44.ua/section/newsInText/upload/images/news/intext/000/054/176/serz-grabarcuk_61a79887657e9.jpg"
  },
  buvet: {
    title: "Бювет",
    photo: "https://dzherelo-lux.com/wp-content/uploads/2020/06/Screenshot-at-Jun-09-14-02-27-720x536.png"
  },
  default: {
    title: "",
    photo: ""
  }
}


export default function Map() {

  const [curInfo, setInfo] = useState(tempData.house2)

  function changeInfo(elId) {
    setInfo(tempData[elId]);
  }

  return (
    <>
      <div className="mpMap" id="mpMap">
        <div className="mpMap__clouds"></div>
        <div className="mpMap__map">
          <MapSvg changeInformation={changeInfo}/>
        </div>
      </div>
      <HoverPopup information={curInfo} key={curInfo}/>
    </>
  );
}
