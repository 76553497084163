import React from 'react';
import {facebookLogo, footerLogo, instagramLogo, telegramLogo} from "../../Assets/Icons/icons";
import "./Footer.css"

const Footer = () => {
    return (<div className={"footer "}>
        <div className="container footer-wrapper">
            <div className="footer-name">
                <img src={footerLogo} alt="Icon: Silver House" className="footer-name__logo"/>
                <div className="footer-name__block">
                    <h2 className="footer-name__title">Назва сайту</h2>
                    <p className="footer-name__text">Оренда квартир у м. Львів на короткий та довгий термін</p>
                    <div className="footer-privacy footer-web">
                        <h2 className="footer-web__item">
                            Всі права захищені
                        </h2>
                        <h2 className="footer-web__item">
                            Політика конфіденційності
                        </h2>
                    </div>
                </div>

            </div>
            <div className="footer-contacts">
                <h2 className="footer-contact">Контакти:</h2>
                <div className="footer-contact__us">
                    <h3 className="footer-contact__us-title">ДЛЯ ЗВ’ЯЗКУ З НАМИ</h3>
                    <a href="tel:+38 (096) 088 44 33" className="footer-contact__us-phone">+38 (096) 088 44 33</a>
                </div>
                <div className="footer-contact__work">
                    <h3 className="footer-contact__us-title">ДЛЯ СПІВПРАЦІ</h3>
                    <a href="mailto:mail@gmail.com" className="footer-contact__us-email">mail@gmail.com</a>
                </div>
            </div>
            <div className="footer-media">
                <h2 className="footer-media__title">Соц. мережі:</h2>
                <div className="footer-media__wrapper">
                    <a href="" className="footer-media__link"><img src={telegramLogo} alt="Icon:telegram"
                                                                   className="footer-media__telegram"/></a>
                    <a href="" className="footer-media__link"><img src={facebookLogo} alt="Icon:facebook"
                                                                   className="footer-media__facebook"/></a>
                    <a href="" className="footer-media__link"><img src={instagramLogo} alt="Icon:instagram"
                                                                   className="footer-media__instagram"/></a>
                </div>

            </div>
            <div className="footer-privacy footer-mb">
                <h2 className="footer-mb__item">
                    Політика конфіденційності
                </h2>
                <h2 className="footer-mb__item">
                    Всі права захищені
                </h2>
            </div>
        </div>
    </div>);
};

export default Footer;
