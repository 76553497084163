import React from 'react';
import "./HouseCard.scss";

import {location_black} from "../../Assets/Icons/icons.js";
import { Link } from "react-router-dom";

export default function HouseCard({info, index}) {
  let myClassCard = "houseCard";
  let myClassPhoto = "houseCard__photo";
  let myClassInfo = "houseCard__info";

  if(index%2===0) {
    myClassCard += " houseCard_invert"
    myClassPhoto += " houseCard__photo_invert"
    myClassInfo += " houseCard__info_invert"
  }

  return(
    <div className={myClassCard}>
      <div style={{backgroundImage: `url(${info.photo})`}} alt="building" className={myClassPhoto}></div>
      <div className={myClassInfo}>
        <div className="houseCard__title">{info.name}</div>
        <div className="houseCard__description">{info.description}</div>
        <div className="houseCard__location">
          <img src={location_black} alt="location"/>
          <span>{info.location}</span>
        </div>
        <Link to={info ? "/gk/" + info.id : "/"} className="houseCard__btn">Переглянути квартири</Link>
      </div>
    </div>
  )
}
