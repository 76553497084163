import React from 'react';
import './navigation.scss';

const Navigation = () => {
    return (
        <div className="header-navigation">
            <a href="#" className="header-navigation__item">Головна</a>
            <a href="#" className="header-navigation__item">Квартири</a>
            <a href="#" className="header-navigation__item">Правила проживання</a>
            <a href="#" className="header-navigation__item">Про нас</a>
            <a href="#" className="header-navigation__item">Відгуки</a>
            <a href="#" className="header-navigation__item">Контакти</a>

        </div>
    );
};

export default Navigation;
