import React from 'react';

const NextArrow = ({img, onClick}) => {
    return (

            <img src={img} alt={img} onClick={onClick} className={'sliderArrow-next'}/>

    );
};
export default NextArrow;
