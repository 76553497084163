import React from 'react';
import Slider from "react-slick";
import {useState} from "react";

import "./Reviews.css"

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import {Client1} from "../../../Assets/Images/images";
import {ArrowLeft, ArrowRight} from "../../../Assets/Icons/icons";

import NextArrow from "./Arrows/NextArrow";
import PrevArrow from "./Arrows/PrevArrow";
import nextArrow from "./Arrows/NextArrow";
import prevArrow from "./Arrows/PrevArrow";
import Review from "./Review";

const reviews = [
    {
        id: 1,
        text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into",
        name: "Прізвище та імʼя",
        proffesion: "Слюсар",
        image: Client1
    },
    {
        id: 2,
        text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus, aliquid corporis, debitis dolore dolores doloribus fugit molestiae non porro quas qui quia quo reprehenderit rerum sint sit suscipit velit veritatis.",
        name: "Прізвище та імʼя",
        proffesion: "Професія",
        image: Client1
    },
    {
        id: 3,
        text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus, aliquid corporis, debitis dolore dolores doloribus fugit molestiae non porro quas qui quia quo reprehenderit rerum sint sit suscipit velit veritatis.",
        name: "Прізвище та імʼя",
        proffesion: "Професія",
        image: Client1
    },
    {
        id: 4,
        text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus, aliquid corporis, debitis dolore dolores doloribus fugit molestiae non porro quas qui quia quo reprehenderit rerum sint sit suscipit velit veritatis.",
        name: "Прізвище та імʼя",
        proffesion: "Професія",
        image: Client1
    },
    {
        id: 5,
        text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus, aliquid corporis, debitis dolore dolores doloribus fugit molestiae non porro quas qui quia quo reprehenderit rerum sint sit suscipit velit veritatis.",
        name: "Прізвище та імʼя",
        proffesion: "Професія",
        image: Client1
    },
]

const Reviews = ({info}) => {

    const [slideindex, setSlideIndex] = useState(0);

    const settings = {
        infinite: true,
        slidesToShow: 3,
        speed: 500,
        dots: true,
        beforeChange: (current, next) => setSlideIndex(next),
        nextArrow: <NextArrow img={ArrowRight} onClick={nextArrow}/>,
        prevArrow: <PrevArrow img={ArrowLeft} onClick={prevArrow}/>,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    nextArrow: <NextArrow img={ArrowRight} onClick={nextArrow}/>,
                    prevArrow: <PrevArrow img={ArrowLeft} onClick={prevArrow}/>,
                }
            }
        ]
    };
    return (
        <div className={'container reviews'}>
            <div className="rules__title">Відгуки:</div>
            <Slider {...settings}>
                {info.map((revie, index) => < Review
                    className={(index || info.length) === slideindex + 1 ? 'slide slide-active' : 'slide'}
                    key={index}
                    name={revie.name}
                    text={revie.review}
                    proffesion={revie.occupation}
                    image={revie.photo}
                />)}
            </Slider>
        </div>
    );
}
export default Reviews;
