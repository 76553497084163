import React, { useEffect, useState } from 'react'
import CustomVideo from './CustomVideo/CustomVideo'
import AboutFlat from './AboutFlat/aboutFlatWeb/AboutFlat'
import AboutFlatMobile from './AboutFlat/aboutFlatMobile/AboutFlatMobile'
import MySlider from './slider/Slider'
import NearFlat from './NearFlat/NearFlat'

import { useParams } from 'react-router'

import data from '../../Constants/data'
import {
  emailIcon,
  fbIcon,
  instIcon,
  managerIcon,
  telIcon,
  tgIcon,
} from '../../Assets/Icons/iconsPage3'
import styles from './AboutFlat/aboutFlatWeb/style.module.css'
import { Link } from 'react-scroll'
import { fb_link, mail, phone } from '../../variable'

const FlatPage = ({ flatName }) => {
  const [windowWidth, setWindowWidth] = useState(window.screen.width)
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.screen.width)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  let { gkId, flatId } = useParams()
  const currentFlat = data[gkId - 1].flats[flatId - 1]

  return (
    <div>
      <CustomVideo />
      {windowWidth > 575 ? (
        <AboutFlat flat={currentFlat} />
      ) : (
        <AboutFlatMobile flat={currentFlat} />
      )}
      <MySlider flatPhotos={currentFlat.photos} />
      <NearFlat />
      {windowWidth > 575 ? (
        <></>
      ) : (
        <div className={`${styles.rightBox} container`}>
          <Link>
            <button className={styles.rightBox__btn}>Забронювати</button>
          </Link>
          <div className={styles.rightBox__map}>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2603.028790785422!2d23.508904912993508!3d49.27585167127254!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x473a484db2ab1bbd%3A0x17c3ab9af31cdd99!2z0LLRg9C70LjRhtGPINCc0LDRgNC60ZbRj9C90LAg0KjQsNGI0LrQtdCy0LjRh9CwLCAzLCDQotGA0YPRgdC60LDQstC10YbRjCwg0JvRjNCy0ZbQstGB0YzQutCwINC-0LHQu9Cw0YHRgtGMLCA4MjIwMA!5e0!3m2!1suk!2sua!4v1692196943168!5m2!1suk!2sua"
              width="100%"
              height="100%"
              style={{ border: '0' }}
              allowFullScreen=""
              loading="lazy"
              title={'myMap'}
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>

          <div className={styles.rightBox_managerBox}>
            <div className={styles.rightBox_managerBox__box}>
              <div>
                <img src={managerIcon} alt={managerIcon} />
              </div>
              <p>Зв’язатись з менеджером</p>
            </div>

            <div className={styles.rightBox_managerBox__contactBoxes}>
              <div className={styles.rightBox_managerBox__contactBoxes__box}>
                <img src={telIcon} alt={telIcon} />
                <a href={`tel:${phone}`}>+380 68 68 68 686</a>
              </div>
              <div className={styles.rightBox_managerBox__contactBoxes__box}>
                <img src={emailIcon} alt={emailIcon} />
                <a href={`mailto:${mail}`}>tempamail@gmail.com</a>
              </div>
            </div>

            <div className={styles.rightBox_managerBox_socialMediaBox}>
              <h5 className={styles.rightBox_managerBox_socialMediaBox__title}>
                Ми у соц. мережах
              </h5>
              <div className={styles.rightBox_managerBox_socialMediaBox__links}>
                <a href={`${fb_link}`}>
                  <div>
                    <img src={tgIcon} alt={tgIcon} />
                  </div>
                </a>
                <a href="https://www.facebook.com/sribnyydim/">
                  <div>
                    <img src={fbIcon} alt={fbIcon} />
                  </div>
                </a>
                <a href="https://www.instagram.com/sribnyydim/?hl=uk">
                  <div>
                    <img src={instIcon} alt={instIcon} />
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default FlatPage
