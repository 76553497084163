import React from 'react';

const PrevArrow = ({img, onClick}) => {
    return (

        <img src={img} alt={img} onClick={onClick} className={'sliderArrow-prev'}/>

    );
};
export default PrevArrow;
