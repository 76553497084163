import React from 'react';
import "./Flats.scss";
import FlatCard from "../../../Modules/FlatCard/FlatCard.js";


const tempData = [
  {
    photo: "https://images.pexels.com/photos/2834211/pexels-photo-2834211.jpeg?cs=srgb&dl=pexels-rahul-pandit-2834211.jpg&fm=jpg",
    name: "Квартира 1",
    location: "Героїв УПА 73",
    price: "750",
    rooms: "2",
    beds: "2",
    area: "36"
  },
  {
    photo: "https://imganuncios.mitula.net/2_bedroom_flat_6470093665494401356.jpg",
    name: "Квартира 2",
    location: "Городоцька 23",
    price: "450",
    rooms: "1",
    beds: "1",
    area: "26"
  },
  {
    photo: "https://www.srijanrealty.com/wp-content/uploads/2022/07/3-bhk-flat-in-kolkata-1200x900.png",
    name: "Квартира 3",
    location: "Замарстинівська 82",
    price: "1000",
    rooms: "3",
    beds: "4",
    area: "45"
  },
  {
    photo: "https://www.srijanrealty.com/wp-content/uploads/2022/07/2-bhk-flats-in-Kolkata-1200x900.png",
    name: "Квартира 4",
    location: "Голубовича 12",
    price: "600",
    rooms: "2",
    beds: "2",
    area: "30"
  },
  {
    photo: "https://i2-prod.mirror.co.uk/incoming/article12016015.ece/ALTERNATES/s1200b/This-is-what-its-like-living-in-one-of-Croydons-smallest-flats.jpg",
    name: "Квартира 5",
    location: "Лесі Українки 40",
    price: "400",
    rooms: "1",
    beds: "2",
    area: "25"
  },
  {
    photo: "https://media-cdn.tripadvisor.com/media/photo-s/11/08/db/6a/inside-view-of-a-studio.jpg",
    name: "Квартира 6",
    location: "Героїв УПА 73",
    price: "750",
    rooms: "2",
    beds: "2",
    area: "36"
  },
]


export default function Flats({info}) {


  return(
    <div className="flats container">
      <div className="flats__title">Наші квартири:</div>
      <div className="flats__flats">
        {
          info.flats.map((el)=>{
            return(
              <FlatCard flat={el} gkId={info.id}/>
            )
          })
        }
      </div>
    </div>
  )
}
