import React, {useEffect, useState} from 'react';
import ReactPlayer from 'react-player';
import { playIcon } from '../../../Assets/Icons/iconsPage3';

const CustomVideo = () => {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);


        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    return (
            <ReactPlayer
                url="https://www.youtube.com/watch?v=iu-LBY7NXD4&t=234s"
                width='100%'
                height={windowWidth > 575 ? '476px' : windowWidth > 360 && windowWidth < 575 ? '548px' : windowWidth < 360 ? '548px' : null}
                controls={false}
                light={true}
                playIcon={<img src={playIcon} alt={playIcon}/>}
                style={windowWidth > 991 ? {marginBottom: '103px'} : windowWidth > 360 && windowWidth < 991 ? {marginBottom: '56px'} : windowWidth < 360 ? {marginBottom: '0'} : null}
            />
    );
};

export default CustomVideo;


