import React from "react";
import {useState} from "react";
import {Controller, useForm} from "react-hook-form";
import "react-phone-input-2/lib/style.css";
import ReactPhoneInput from "react-phone-input-2";
import "./form.css";

const Form = ({formClass, formWrapper, formTitle, formDecorate, isFirst}) => {

    let redBord = {border: "2px solid red"}
    let blackBord = {border: "none"}

    const {
        register,
        control,
        handleSubmit,
        formState: {errors, isValid},
    } = useForm({mode: "onBlur"});
    const [formData, setFormData] = useState({});
    let disabledButton = {background: "grey"}

    function AnimateLoading(){
      let mainDiv = [...document.getElementsByClassName("send-form")]

      mainDiv.map((el)=>{
        el.getElementsByClassName("check")[0].classList = "check check-complete success";
        [...el.getElementsByClassName("fill")].map((el)=>{
          el.classList = "fill fill-complete success";
          return null
        })
        el.getElementsByClassName("path")[0].classList = "path path-complete";
        setTimeout(function () {
          el.innerHTML = 'ЗАЛИШИТИ ЗАЯВКУ';
        }, 5000);
        return null
      })


    }

    function onSubmit(data) {

      console.log(isFirst)
      let messageTitle = "";
      if(!isFirst) messageTitle = "Авторський річний курс"
      else messageTitle = "Перше безкоштовне заняття"
      setFormData(data);
      const msg = `
      Нова заявка на ${messageTitle}
      Ім'я: ${data.name}
      Номер телефону: ${data.phone}`

      let btnElement = [...document.getElementsByClassName("send-form")]
      btnElement.map((el)=>{
        el.innerHTML = `<div id="vacancies__input_loading">${loadingHTML}</div>`;
        return null
      })

      fetch(baseUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          validator: encodedToken,
          chat_id: botId,
          message: msg,
        }),
      })
      .then((response) => response.json())
      .then((data) => {
        AnimateLoading()
      })
      .catch((error) => {
        btnElement.map((el)=>{
          el.innerHTML = `Спробуйте ще раз..`;
          return null
        })
        setTimeout(function () {
          btnElement.map((el)=>{
            el.innerHTML = `ЗАЛИШИТИ ЗАЯВКУ`;
            return null
          })
        }, 5000);
      });
    };

    return (
      <div className={"form-wrapper"}>
          <h2 className="form-title">
              Залишайте заявку
          </h2>
          <p className="form-text">і наш менеджер зв'яжеться з вами і відповість на всі питання!</p>
        <form onSubmit={handleSubmit(onSubmit)} className={"form"}>
          <input
            type="text"
            name="name"
            required

            className={`name ${formData.name ? "filled" : ""} ${
              errors.name ? "error" : ""
            }`}
            {...register("name", {
              required: true,
              pattern: new RegExp(/^[a-zA-ZА-яіІїЇҐґєЄ]{4,25}$/),
            })}
            onInput={(e) => {
                e.target.value = e.target.value.replace(/[0-9]/g, ""); // Видаляємо всі цифри
            }}
            style={errors.name ? redBord : blackBord}
            placeholder={"Ваше ім'я"}
          />
          <Controller
            name="phone"
            control={control}
            style={errors.phone ? redBord : blackBord}
            rules={{
                required: true,
                valueAsNumber: true,
                pattern: new RegExp(/^[\]?[(]?[0-9]{3}[)]?[-\s. ]?[0-9]{3}[-\s.]?[0-9]{4,9}$/)
            }}
            render={({ field }) => (
              <ReactPhoneInput
                {...field}
                country="ua"
                placeholder="Ваш номер телефону"
                inputExtraProps={{
                  required: true,
                  autoComplete: "tel",
                  valueAsNumber: true,
                }}
              />
            )}
          />

          <button style={isValid ? null : disabledButton} disabled={!isValid}
            type="submit"
            className="send-form"
            id="form__input_button"
          >
            Залишити заявку
          </button>
        </form>
      </div>
    );
};

export default Form;
