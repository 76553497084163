import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import RightNav from './RightNav/RightNav';
import './burgerMenu.css'

const StyledBurger = styled.div`
;
  position: fixed;
  top: 50px;
  right: -10px;
  display: none;
  z-index: 15;
  background: #212121;
  width: 50px;
  height: 34px;
  padding: ${({open}) => open ? '0 0' : '5px 0'};
  border-radius: 5px;
  @media (max-width: 576px) {
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;
  }

  div {
    width: 2rem;
    height: 0.25rem;
    background-color: ${({open}) => open ? '#ccc' : '#333'};
    border-radius: 10px;
    transform-origin: 1px;
    transition: all 0.3s linear;

    &:nth-child(1) {
      transform: ${({open}) => open ? 'rotate(45deg)' : 'rotate(0)'};
    }

    &:nth-child(2) {
      transform: ${({open}) => open ? 'translateX(100%)' : 'translateX(0)'};
      opacity: ${({open}) => open ? 0 : 1};
    }

    &:nth-child(3) {
      transform: ${({open}) => open ? 'rotate(-45deg)' : 'rotate(0)'};
    }
  }

  @media (max-width: 576px) {
    top: 25px;
  }
`;

const BurgerMenu = () => {
    const [open, setOpen] = useState(false)

    function SetClose(){
      setOpen(false);
    }

    useEffect(()=>{
      if(open){
        document.getElementsByTagName("body")[0].style.overflowY = "hidden";
        document.getElementById("burger__overlay").style.display = "block";
      } else {
        document.getElementsByTagName("body")[0].style.overflowY = "scroll";
        document.getElementById("burger__overlay").style.display = "none";
      }
    }, [open])

    function showPopup(event){
      event.target.style.display="none"
      setOpen(false)
    }


    return (
        <>
            <StyledBurger open={open} onClick={() => setOpen(!open)}>
                <div className="burger-button"/>
                <div className="burger-button"/>
                <div className="burger-button"/>
            </StyledBurger>
            <RightNav open={open} setClose={SetClose}/>
            <div 
              className="overlay" 
              id="burger__overlay"
              onClick={showPopup}
            ></div>
        </>
    )
}

export default BurgerMenu
