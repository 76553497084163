import {React, useState} from "react";
import Navigation from "./Navigation/Navigation";
import "./header.css";
import BurgerMenu from "./BurgerMenu/BurgerMenu";

import Snackbar from "@mui/material/Snackbar";
import {Alert} from "@mui/material";

const Header = () => {
    function copyText(event) {
        let number = event.target.textContent;
        navigator.clipboard.writeText(number);
        setOpen(true);
    }

    const [open, setOpen] = useState(false);

    const handleSendErrorClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };

    return (
        <div className="header ">
            <div className="header-wrapper container">
                <div className="header-firstblock">
                    <img alt="" className="header-logo"/>
                    <div
                        type="phone"
                        href="tel:+380 (98) 324 72 80"
                        className="header-phone"
                        onClick={copyText}
                    >
                        +380 (98) 324 72 80
                    </div>
                    <Navigation/>
                </div>
                <BurgerMenu/>

                <Snackbar
                    open={open}
                    autoHideDuration={4000}
                    onClose={handleSendErrorClose}
                >
                    <Alert
                        severity="success"
                        open={open}
                        autoHideDuration={4000}
                        onClose={handleSendErrorClose}
                    >
                        Номер скопійовано
                    </Alert>
                </Snackbar>
            </div>
        </div>
    );
};

export default Header;
