import React from 'react';
import "./Rules.css"
import {Dogs_rules, Hours_rules, Time_rules,} from "../../../Assets/Icons/icons";

const Rules = () => {
    return (
        <div className={'container rules'}>
            <div className="rules__title">Правила проживання:</div>
            <div className={'rules-logo__info'}>
                <div className={'rules-info'}>
                    <img className={'rules-logo'} src={Dogs_rules} alt="dogs_logo"/>
                    <p className={'rules-logo__text'}>
                        Дозволяється проживання <br/>з тваринами до 5 кг
                    </p>
                </div>
                <div className={'rules-info'}>
                    <img className={'rules-logo'} src={Hours_rules} alt="hours_logo"/>
                    <p className={'rules-logo__text'}>
                        Заїзд цілодобовий <br/> за попередженням
                    </p>
                </div>
                <div className={'rules-info'}>
                    <img className={'rules-logo'} src={Time_rules} alt="time_logo"/>
                    <p className={'rules-logo__text'}>
                        Виїзд до 12:00
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Rules;
