import React from 'react';
import './Hat.css'
import {Dolphin_logo, Location_logo, Park_logo} from "../../../Assets/Icons/icons";


const Hat = ({info}) => {
    return (
        <div className={'back-hat'} style={{backgroundImage: `url(${info.photo})`}}>
            <div className={'container hat-content'}>

                <div className={'hat-heading__text'}>
                    <h2 className={'hat-text'}>
                        {info.name}
                    </h2>
                </div>
                <div className={'hat-logo__info'}>
                    <div className={'hat-info'}>
                        <img className={'hat-logo'} src={Location_logo} alt="location_logo"/>
                        <p className={'hat-logo__text'}>
                            1 км - Бювет <br/> мінеральних вод
                        </p>
                    </div>
                    <div className={'hat-info'}>
                        <img className={'hat-logo'} src={Park_logo} alt="park_logo"/>
                        <p className={'hat-logo__text'}>
                            500 м - Парк <br/> ”Адамівка”
                        </p>
                    </div>
                    <div className={'hat-info'}>
                        <img className={'hat-logo'} src={Dolphin_logo} alt="dolphin_logo"/>
                        <p className={'hat-logo__text'}>
                            1.3 км - Дельфінарій <br/> ”Оскар”
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Hat;
