import React from 'react';
import "./Houses.scss";

import HouseCard from "../../../Modules/HouseCard/HouseCard.js";

import data from "../../../Constants/data.js";

export default function Houses() {

  return(
    <div className="houses container">

      {
        data.map((el, index)=>{
          return(
            <HouseCard info={el} index={index+1}/>
          )
        })
      }
      
    </div>
  )
}
