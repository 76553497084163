import React from 'react';
import Slider from "react-slick";
import {
    photo01,
    photo02,
    photo03,
    photo04,
    photo05,
    photo06,
    photo07,
    photo08
} from "../../../Assets/Images/page3Image";
import './stylesSlider.css'
import {sliderButtonLeft, sliderButtonRight} from "../../../Assets/Icons/iconsPage3";


function NextArrow({ className, onClick }) {
    return (
        <img src={sliderButtonRight} alt={sliderButtonRight}
            className={className}
            onClick={onClick}
        />
    );
}

function PrevArrow({ className, onClick }) {
    return (
        <img src={sliderButtonLeft} alt={sliderButtonLeft}
            className={className}
            onClick={onClick}
        />
    );
}


const MySlider = ({flatPhotos}) => {

    const ph = flatPhotos;

    console.log(ph);


    const settings = {
        customPaging: function (i) {

            return (
                <a style={{width: 'auto', height: 'auto'}}>
                    <img className={'dot'} src={`${ph[i]}`} alt={`${ph[i]}`}/>
                </a>

            );
        },
        dots: true,
        dotsClass: "slick-dots slick-thumb",
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        width: '100%',
        nextArrow: <NextArrow className={'nextButton'}/>,
        prevArrow: <PrevArrow className={'prevButton'}/>
    };


    return (
      <div className={"container box gkSlider"}>
        <Slider {...settings}>
          {ph.map((el) => {
            return (
              <div className={"photoCard"}>
                <img src={el} alt="flat" className={"photoCard"} />
              </div>
            );
          })}
        </Slider>
      </div>
    );
};

export default MySlider;

