import {React, useEffect} from 'react';
import "./HoverPopup.scss";
import {useParams} from 'react-router';

export default function HoverPopup({information}) {

  let {gkId, flatId} = useParams();
  console.log(gkId == undefined)

  useEffect(()=>{
    var mouseX = 0,
        mouseY = 0;
    var xp = 0,
        yp = 0;

    document.getElementsByTagName("body")[0].addEventListener("mousemove", (e)=>{
      mouseX = e.pageX + 20;
      mouseY = e.pageY + 20;
    })

    let hoverPopup = document.getElementById("hoverPopup");

    setInterval(function () {
      xp += (mouseX - xp) / 6;
      yp += (mouseY - yp) / 6;
      hoverPopup.style.left = xp + "px"
      hoverPopup.style.top = yp + "px"
    }, 10);
    
  }, [])

  if(information.photo === "" && information.title === "") {
    return (
      <span id="hoverPopup" className="hoverPopup">

      </span>
    )
  }

  
  return (
    <span id="hoverPopup" className="hoverPopup">
      <img src={information.photo} alt="building" className="hoverPopup__photo"/>
      <div className="hoverPopup__title">{information.title}</div>
    </span>
  )
}
