import React from 'react';
import "./Hat.scss";

import {mainPage_hat} from "../../../Assets/Images/images.js";
import {location, time, buildings} from "../../../Assets/Icons/icons.js";

export default function Hat() {

  return(
    <div className="hat" style={{backgroundImage: 'url(' + mainPage_hat +')'}}>
      <div className="hat__info"> </div>

      <div className='hat__infoContainer container'>
          <div className="hat__title">Подобова оренда <br/> квартир в Трускавці</div>

          <div className="hat__icons mpIcons">
            <div className="mpIcons__container">
              <img src={location} alt='location' className='mpIcons__icon'/>
              <div className="mpIcons__text">
                Центр трускавця
              </div>
            </div>

            <div className="mpIcons__container">
              <img src={buildings} alt='buildings' className='mpIcons__icon'/>
              <div className="mpIcons__text">
                Сучасний ремонт
              </div>
            </div>

            <div className="mpIcons__container">
              <img src={time} alt='time' className='mpIcons__icon'/>
              <div className="mpIcons__text">
                Цілодобовий заїзд
              </div>
            </div>
          </div>
        </div>
    </div>
  )
}
