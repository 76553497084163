import React from "react";
import styled from "styled-components";

const Ul = styled.ul`
  list-style: none;
  display: none;
  flex-flow: row nowrap;

  li {
    padding: 18px 10px;
  }

  .navbar-media {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
  }

  .navbar-media__item {
    margin: 10px;
  }

  .navbar-media__img {
    width: 50px;
    height: 50px;
  }

  @media (max-width: 1024px) {
    list-style: none;
    display: flex;
    flex-flow: row nowrap;
    flex-flow: column nowrap;
    background-color: rgba(33, 33, 33, 1);
    position: fixed;
    transform: ${({open}) => (open ? "translateX(0)" : "translateX(100%)")};
    top: 0;
    right: 0;
    z-index: 10;
    height: 100%;
    width: 50%;
    padding: 0;
    padding-top: 3.5rem;
    transition: transform 0.3s ease-in-out;
    .sc-gsDKeI, .kxYeGT {
      Z-index: 10;
    }
  }

  .navbar-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 44.897px;
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
    background-color: white;
    border: none;
    border-radius: 4px;
    color: black;
    font-family: Gilroy, sans-serif;
    font-size: 11.776px;
    font-style: normal;
    font-weight: 700;
    line-height: 19.635px;
  }

  .navbar-wrapper__button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;

    li {
      color: #fff;
    }
  }

  @media (max-width: 576px) {
    width: 70%;
  }

  .navbar-media__img {
    width: 40px;
    height: 40px;
  }
`;

const RightNav = ({open, setClose}) => {
    return (
        <Ul open={open}>
            <div className="mobile-navigation">
                <a href="#aboutUs" className="mobile-navigation__item" onClick={setClose}>
                    Головна
                </a>
                <a href="#services" className="mobile-navigation__item" onClick={setClose}>
                    Квартири
                </a>
                <a href="#schoolMPlus" className="mobile-navigation__item" onClick={setClose}>
                    Правила проживання
                </a>
                <a href="#feedbacks" className="mobile-navigation__item" onClick={setClose}>
                    Про нас
                </a>
                <a href="#vacancies" className="mobile-navigation__item" onClick={setClose}>
                    Відгуки
                </a>
                <a href="#vacancies" className="mobile-navigation__item" onClick={setClose}>
                    Контакти
                </a>
            </div>
        </Ul>
    );
};

export default RightNav;
