import React from 'react';
import "./FlatCard.scss"
import { Link } from "react-router-dom";


import {area,
  bed,
  door,
  price} from "../../Assets/Icons/icons.js";

export default function FlatCard({flat, gkId}) {

  return (
    <Link to={`/flat/${gkId}/${flat.id}`} className="flatCard">
      <div className="flatCard__photo" style={{backgroundImage: `url(${flat.photos[0]})`}}></div>
      <div className="flatCard__info">
        <div className="flatCard__title">
          <div className="flatCard__title_big">{flat.name}</div>
          <div className="flatCard__title_small">{flat.location}</div>
        </div>

        <div className="flatCard__price">
          <img
            src={price}
            className="flatCard__icon flatCard__icon_big"
            alt="price"
          />
          <div className="flatCard__priceNumber">
            {flat.price} грн /{" "}
            <span className="flatCard__priceNumber_small">1 доба</span>
          </div>
        </div>

        <div className='flatCard__icons icons'>
          <div className='icons__container'>
            <img src={door} className="icons__icon"/>
            <div className="icons__text">{`${flat.rooms}-х кімнатна квартира`}</div>
          </div>

          <div className='icons__container'>
            <img src={bed} className="icons__icon"/>
            <div className="icons__text">{`${flat.beds} спальних місця`}</div>
          </div>

          <div className='icons__container'>
            <img src={area} className="icons__icon"/>
            <div className="icons__text">{`Площа: ${flat.area} м²`}</div>
          </div>
        </div>
      </div>
    </Link>
  );
}
